<template>
  <div class="wrapper full-screen">
    <b-container class="px-lg-5 black full-screen" fluid>
      <div class="container">
        <b-navbar toggleable="lg" v-bind:type="'dark'" data-aos="fade-down" sticky>
          <b-navbar-brand href="/">
            <img src="../assets/logo.svg" />
            Codelos.io
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown text="Lösungen" id="solutions-menu" right>
                <ul>
                  <li>
                    <a class="dropdown-item" href="/#sales-section"
                      >Für den Vertrieb</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="/#data-section"
                      >Für das Datenmanagement</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="/#automators-section"
                      >Für Citizen Automators</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="/#developers-section"
                      >Für Softwareentwickler</a
                    >
                  </li>
                </ul>
              </b-nav-item-dropdown>
              <b-nav-item href="/developer" right>Developer</b-nav-item>
              <b-nav-item href="/about" right>Über uns</b-nav-item>
              <b-nav-item href="https://codelos.notion.site/Wir-freuen-uns-auf-deine-Bewerbung-e066250a894a42b9af365c7ffa518dd7" target="_blank" right>Jobs</b-nav-item>
              <b-nav-item href="https://www.app.codelos.io/" right
                >Login</b-nav-item
              >
<b-nav-item href="mailto:team@codelos.io" right>Kontakt aufnehmen</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <div class="header">
          <div class="row">
            <div
              class="col-md-12 col-xl-4 d-flex py-5 px-4 align-items-center"
              data-aos="fade-up"
            >
              <div>
                <h1>Lass uns gemeinsam <br>deine erste Anwendung mit Codelos.io zusammenklicken.</h1>
                <p class="mt-5">
                  Wir zeigen dir gerne in einer Live-Demo, wie einfach es ist, Anwendungen mit dem Drag-and-drop Workflow-Builder von Codelos.io zu erstellen.
                </p>
              </div>
            </div>
            <div
              class="col-md-12 col-xl-8 py-md-5 pr-sm-3 d-flex align-items-center"
              data-aos="fade-left"
            >
              <div class="screens-wrapper">
                <div
                  id="calendy"
                  class="calendly-inline-widget"
                  data-url="https://calendly.com/gharnati/produkt-demo-codelos-io?text_color=212529&primary_color=000000&hide_event_type_details=1"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
export default {
  name: "DemoAutomators",
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    document.head.appendChild(recaptchaScript);
  },
};
</script>

<style scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
}

#calendy .hidden {
  visibility: hidden !important;
}

.wrapper {
  width: 100%;
  text-align: left;
}

/**.

Navbar

 */

@import "https://use.fontawesome.com/releases/v5.6.3/css/all.css";
@import "https://fonts.googleapis.com/css?family=Hammersmith+One|Nunito|Orbitron:500|Poiret+One";

/*
font-family: 'Orbitron', sans-serif;
font-family: 'Hammersmith One', sans-serif;
font-family: 'Poiret One', cursive;
font-family: 'Nunito', sans-serif;
*/
/** Navbar **/

/** end Navbar **/

/** Container **/
/** Container **/
.container {
  height: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.header {
  text-align: left;
}

.header h1 {
  margin: 0px;
  font-size: 70px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.035em;
  text-transform: none;
}

.lead {
  padding-top: 90px;
  padding-bottom: 90px;
}

/** end Container **/

/* footer */
.footer {
  padding-top: 110px;
  text-align: left;
  padding-bottom: 50px;
  padding-left: 0;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer ol,
ul {
  padding-left: 0px;
}

.inline-list {
  list-style: unset;
}

.inline-list li {
  display: inline-block;
  margin-right: 15px;
}

.inline-list li a:hover {
  text-decoration: underline;
}
/* end footer */

@media screen and (max-width: 479px) {
  .header h1 {
    font-size: 30px;
  }
  .header p {
    font-size: 14px;
    padding-bottom: 25px;
  }
  .preview-element {
    margin-top: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .nav-item {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
  }
}

.full-screen {
  min-height: 100vh;
  height: 100vh;
}

.screens-wrapper {
  position: relative;
  margin-left: auto;
  background: #794cff;
  border-radius: 22px;
}

.navbar-dark .navbar-brand {
  color: #e4e1e1;
  font-size: 27px;
  font-weight: 500;
}

::v-deep .navbar-dark .navbar-nav .nav-link {
  color: rgb(255 255 255) !important;
  font-weight: 700 !important;
}

.navbar {
  background: black;
}

.navbar-brand img {
  height: 40px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .nav-item {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
  }

  ::v-deep .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: fit-content;
    left: auto;
    right: auto;
    -webkit-animation: show-menu-data-v-8dc7cce2;
    animation: show-menu-data-v-8dc7cce2;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    border-radius: 7px;
    box-shadow: rgb(51 51 51 / 50%) 0px 20px 40px -30px;
    border: solid 0.6px #eeecf2;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f0f0f0;
    color: white;
    font-weight: 900;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-content {
    margin: 100px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu h2 {
    font-size: 17px;
    font-weight: 700;
    padding: 0rem 0rem 1em 2rem;
    border-bottom: solid 1px #cdcdcd;
    margin-bottom: 1rem;
  }

  ::v-deep .dropdown-item {
    font-size: 16px;
  }

  .ml-auto {
    margin-left: auto !important;
  }
}
</style>