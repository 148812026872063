<template>
  <div class="wrapper">
    <b-container class="black px-lg-5" fluid>
      <div class="container">
        <b-navbar
          toggleable="lg"
          v-bind:type="navbarType"
          data-aos="fade-down"
          sticky
        >
          <b-navbar-brand href="/">
            <img src="../assets/logo.svg" />
            Codelos.io
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown text="Lösungen" id="solutions-menu" right>
                <ul>
                  <li>
                    <a class="dropdown-item" href="/#sales-section"
                      >Für den Vertrieb</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="/#data-section"
                      >Für das Datenmanagement</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="/#automators-section"
                      >Für Citizen Automators</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="/#developers-section"
                      >Für Softwareentwickler</a
                    >
                  </li>
                </ul>
              </b-nav-item-dropdown>
              <b-nav-item href="/developer" right>Developer</b-nav-item>
              <b-nav-item href="/about" right>Über uns</b-nav-item>
              <b-nav-item href="https://codelos.notion.site/Wir-freuen-uns-auf-deine-Bewerbung-e066250a894a42b9af365c7ffa518dd7" target="_blank" right>Jobs</b-nav-item>
              <b-nav-item href="https://www.app.codelos.io/" right
                >Login</b-nav-item
              >
<b-nav-item href="mailto:team@codelos.io" right>Kontakt aufnehmen</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <div class="header">
          <div class="row">
            <div
              class="col-md-12 col-xl-12 d-flex py-5 px-4 align-items-center"
              data-aos="fade-up"
            >
              <div>
                <h1>Besserer Überblick. Mehr Effizienz. Weniger Fehler.</h1>
                <p v-bind:class="{ active: loaded == true }" class="mt-5">
                  Alle Daten, immer aktuell, an deinen Fingerspitzen. Halte
                  deine gesamte Organisation synchron.
                </p>
                <b-button variant="primary" href="/demo">Jetzt loslegen</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container class="mt-5 mb-5">
      <div class="section">
        <div class="container text-dark mt-0">
          <div class="row feature" id="sales-section">
            <div class="col-md-7" align="left" data-aos="fade-right">
              <div class="video-wrapper">
                <video
                  ref="video3"
                  class="video-example"
                  loop="loop"
                  autoplay="autoplay"
                  playsinline
                  preload="none"
                  muted="muted"
                  alt="Lastprofile importieren mit codelos data"
                  poster="../assets/developer.png"
                  v-playback-rate="1"
                >
                  <source
                    src="https://res.cloudinary.com/gharnati-com/video/upload/v1658414500/Bildschirmaufnahme_2022-07-21_um_16.40.39_i2ybax.mov"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div
              class="
                col
                description-right
                justify-content-center
                align-self-center
              "
            >
              <div>
                <h3>Einfacher Datenimport</h3>
                <h2>Importiere deine Daten frustfrei und effizient</h2>
                <p>
                  Daten kommen oft in den unterschiedlichsten Formaten und
                  Strukturen. Das Einlesen dieser Daten ins System kann eine
                  echte Herausforderung sein. Von unterschiedlichen
                  Dateicodierungen bis hin zu hunderte verschiedene
                  Datumsformaten, das Teilen einer Excel-Vorlage reicht oft
                  nicht aus.
                </p>
                <a class="mt-3 mb-5 btn" href="/dataimport" target="_self"
                  >Mehr erfahren</a
                >
              </div>
            </div>
          </div>
          <div class="row feature" id="data-section">
            <div
              v-if="isMobile"
              class="col-md-7"
              align="left"
              data-aos="fade-right"
              >
              <img
                alt="Wallbox, SLP Profile, Lastgangdaten, Energiedaten"
                class="icon icon-lg"
                src="../assets/data.png"
              />
            </div>
            <div
              class="
                col
                description-leftight
                justify-content-center
                align-self-center
              "
            >
              <div>
                <h3>Alles an einem Ort</h3>
                <h2>Bringe alle deine Daten zusammen</h2>
                <p>
                  Manage alles, von Verbrauch- und Preiszeitreihen, über
                  Portfolios bis hin zu Kunden-, Leads-, Angebots- und
                  Vertriebsdaten, an einem Ort. Integriere alle vorhandene
                  Systeme, um wertvolle Einblicke zu gewinnen und stelle durch Real-time data sicher, dass dein Team sich an Änderungen in
                  Echtzeit orientiert und die besten Aktionen und Entscheidung
                  ableitet.
                </p>
                <a class="mt-3 mb-5 btn" href="/demo" target="_self"
                  >Demo vereinbaren</a
                >
              </div>
            </div>
            <div
              v-if="!isMobile"
              class="col-md-7"
              align="right"
              data-aos="fade-left"
            >
              <img
                alt="Energiedatenmanagement EDM CRM"
                class="icon icon-lg"
                src="../assets/data.png"
              />
            </div>
          </div>
          <div class="row feature" id="aotomators-section">
            <div class="col-md-7" align="left" data-aos="fade-right">
              <img
                alt="Energiedaten reporting"
                class="icon icon-lg"
                src="../assets/report.png"
              />
            </div>
            <div
              class="
                col
                description-right
                justify-content-center
                align-self-center
              "
            >
              <div>
                <h3>Schnelles Reporting</h3>
                <h2>Halte alle auf dem Laufenden</h2>
                <p>
                  Generiere automatisch Berichte, Status-Readouts und mehr, um alle Entscheider auf dem Laufenden zu halten und die richtigen Erkenntnisse zu ziehen. Beginne mit vorgefertigten Vorlagen und passe sie genau an die eigenen Bedürfnisse an.
                </p>
                <a class="mt-3 mb-5 btn" href="/demo" target="_self"
                  >Demo vereinbaren</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container fluid class="light-gray-blue">
      <div class="container">
        <div class="footer">
          © Codelos.io GmbH
          <br /><br />
          Mit ❤️ gemacht in München, Berlin, Porto und Ko Samui.
          <br /><br />
          <ul class="inline-list">
            <li>
              <u><a href="/impressum">Impressum</a></u>
            </li>
            <li>
              <u><a href="/privacy">Datenschutzerklärung</a></u>
            </li>
            <li>
              <a
                href="https://www.facebook.com/Codelosio-GmbH-103666008172517"
                target="blank"
                class="mr3"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/codelos"
                target="blank"
                class="mr3"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
            <!--<li>
              <a
                href="https://www.xing.com/pages/codelos-iogmbh"
                target="blank"
                class="mr3"
                ><i class="fab fa-xing"></i
              ></a>
            </li>-->
            <li>
              <a
                href="https://www.instagram.com/codelos.io/"
                target="blank"
                class="mr3"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      loaded: false,
      navbarType: "dark",
    };
  },
  methods: {
    changeNavbarType() {
      this.navbarType == (this.navbarType == "light" ? "dark" : "light");
    },
  },
  mounted() {
    setTimeout(() => (this.loaded = true), 9000);
  },
   computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  height: 100%;
}

/** Navbar **/
.navbar-brand img {
  height: 40px;
}

.navbar-light {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  
}

.nav-link.dropdown-toggle {
  color: #000;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #000;
}

::v-deep .dropdown-menu {
  background: #ffff;
  margin-top: 18px;
}

::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.28rem 2rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

::v-deep .dropdown-toggle {
  outline: none;
}

/** Container **/
.container {
  height: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.header {
  text-align: left;
}

.header h1 {
  margin: 0px;
  font-size: 91px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.035em;
  text-transform: none;
}

.header p {
  margin-bottom: 5vh;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.01em;
  font-family: Pretendard, sans-serif;
}

.banner {
  text-align: center;
}

.banner h1 {
  margin: 0px;
  font-size: 60px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.035em;
  text-transform: none;
}

.lead {
  padding-top: 60px;
  padding-bottom: 60px;
}



.black .btn-primary {
  background-color: white;
  color: black;
}

.btn-primary {
  font-family: Pretendard, sans-serif;
  font-size: 26px;
  line-height: 20px;
  padding: 27px 57px;
  transition: background-color 200ms ease, color 200ms ease;
  border: none;
  border-radius: 38px;
  font-weight: 600;
}

@media (max-width:500px) {
  .black .btn-primary,  .btn-primary{
    padding-left: 35px;
    font-size: 22px;
    padding-right: 35px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.black .btn-primary:hover {
  background-color: #ffffffd9;
  transition: 0.4s;
}

.btn-primary:hover {
  background-color: #d4d1d1;
}


video {
  width: 100%;
  border-radius: 30px;
}

.demos {
  margin-top: 25px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  
}

.preview-element {
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 31%);
  transition-property: border-color;
  transition-duration: 350ms;
}

.video-wrapper {
  padding: 0;
  margin-top: 6%;
  margin-bottom: 6%;
}

.orange {
  background-color: #ff7745;
}

.blue {
  background-color: #3b85f7;
}

.mauve {
  background-color: #944ff6;
}

.green {
  background-color: #6eecd5;
}

.gray {
  background-color: #cdcdcd;
}

.light-gray-blue-gradient {
  background: linear-gradient(-45deg, #fff2f5, #f7f5ff, #edfbff);
  background: #f2f8ff;
}

.light-gray-blue {
  background: #f3f4f6;
}

.black {
  background: black;
  color: white;
}

.black .btn-primary {
  background-color: white;
  color: black;
}

.light-gray {
  background-color: #f8f9fc;
}

.preview-element .title {
  text-align: center;
  padding: 19px;
  font-size: 19px;
  font-weight: 500;
  background: #ffff;
}

/* footer */
.footer {
  padding-top: 110px;
  text-align: left;
  padding-bottom: 50px;
  padding-left: 0;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer ol,
ul {
  padding-left: 0px;
}

.inline-list {
  list-style: unset;
}

.inline-list li {
  display: inline-block;
  margin-right: 15px;
}

.inline-list li a:hover {
  text-decoration: underline;
}
/* end footer */

.dropdown-menu {
  border-radius: 0px;
}

.highlight {
  background-image: linear-gradient(
    to right,
    rgb(255 231 133),
    rgb(255 231 133)
  );
  background-size: 0% 1.12em;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: background-size 2000ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.active .highlight {
  background-size: 100% 1.12em;
}

.drag-n-drop-anim {
  animation: drag;
  animation-duration: 3s;
  position: relative;
  display: block;
}

.change-anim-list {
  overflow: hidden;
  height: 70px;
}

.change-anim-item {
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  position: relative;
  display: block;
}

.fa-mouse-pointer {
  font-size: 38px;
  color: black;
  margin-top: -22px;
  display: block;
  margin-left: 49px;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffff;
  height: 22px;
}

@keyframes change {
  0%,
  12.66%,
  100% {
    top: 0px;
  }
  16.66%,
  29.32% {
    top: -85px;
  }
  33.32%,
  45.98% {
    top: -170px;
  }
  49.98%,
  62.64% {
    top: -255px;
  }
  66.64%,
  79.3% {
    top: -340px;
  }
  83.3%,
  95.96% {
    top: -425px;
  }
}

@keyframes drag {
  0% {
    left: -600px;
    top: 900px;
  }
  30% {
    left: -600px;
    top: 900px;
  }
  60% {
    left: 60px;
    top: 20px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

@keyframes zoom-in {
  0% {
    width: 0%;
    height: 0px;
  }
  75% {
    width: 30%;
    height: 50px;
  }
  100% {
    width: 100%;
    height: 148px;
  }
}

@keyframes show-menu {
  0% {
    opacity: 0;
    margin-left: -20px;
    width: 60%;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@media screen and (max-width: 479px) {
  .header h1 {
    font-size: 50px;
  }
  .header p {
    font-size: 14px;
    padding-bottom: 25px;
  }
  .preview-element {
    margin-top: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .nav-item {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
  }

  ::v-deep .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: fit-content;
    left: auto;
    right: auto;
    -webkit-animation: show-menu-data-v-8dc7cce2;
    animation: show-menu-data-v-8dc7cce2;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    border-radius: 7px;
    box-shadow: rgb(51 51 51 / 50%) 0px 20px 40px -30px;
    border: solid 0.6px #eeecf2;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f0f0f0;
    color: white;
    font-weight: 900;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-content {
    margin: 100px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu h2 {
    font-size: 17px;
    font-weight: 700;
    padding: 0rem 0rem 1em 2rem;
    border-bottom: solid 1px #cdcdcd;
    margin-bottom: 1rem;
  }

  ::v-deep .dropdown-item {
    font-size: 16px;
  }
}

/*@media (min-width: 1200px) {

}*/

::v-deep .dropdown-menu {
  padding: 27px 0 26px;
}

.container.gray {
  background-color: #f7f7f9;
}

.header img {
  box-shadow: 0 1px 10px rgb(0 0 0 / 7%), 0 2.4px 5.7px rgb(0 0 0 / 2%),
    0 4px 11.3px rgb(0 0 0 / 2%), 0 10.6px 17.5px rgb(0 0 0 / 2%),
    0 14px 25.7px rgb(0 0 0 / 3%), 0 35.5px 39.6px rgb(0 0 0 / 4%),
    0 40px 48px rgb(0 0 0 / 3%);
  border-radius: 9px;
}

.screens-wrapper {
  width: 100%;
}

.screen-1 {
  margin-top: 36%;
  left: 5%;
  z-index: 1;
  position: relative;
  float: left;
  width: 39%;
}

.screen-2 {
  left: 0px;
  margin-top: 0px;
  position: relative;
  float: right;
  width: 61%;
}

.row.feature img {
  width: 100%;
  border-radius: 22px;
  margin-top: 6%;
  margin-bottom: 6%;
}

.container {
  max-width: 1443px;
}

.container-fluid {
  width: 100%;
}

@media screen and (min-width: 479px) {
  .container-fluid .container {
    padding-right: 60px;
    padding-left: 60px;
  }
}

h3 {
  font-size: calc(0.7rem + 0.6vw);
}

h2 {
  font-family: Avenir Next, Avenir Next W01, Avenir, helvetica, arial;
  font-size: 1.77777778rem;
  line-height: 2.66666667rem;
  margin-bottom: 0.5em;
  font-weight: 700;
}

@media (min-width: 1200px) {
  h2 {
    font-size: 2.3rem;
  }
}

.description-right {
  padding-left: 5%;
}

.description-left {
  padding-right: 5%;
}

.ml-auto {
  margin-left: auto !important;
}

.navbar-dark .navbar-brand {
  color: #e4e1e1;
  font-size: 27px;
  font-weight: 500;
}

::v-deep .navbar-dark .navbar-nav .nav-link {
  color: rgb(255 255 255) !important;
  font-weight: 700 !important;
}

.navbar {
  background: black;
}

ul {
  list-style-type: none;
  list-style: none;
}

.row.feature .btn {
  text-decoration: none;
  color: white;
  border: 3px solid black;
  border-radius: 0.375em;
  cursor: pointer;
  display: inline-block;
  font-family: proxima-nova, "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  line-height: 23px;
  padding: 0.5rem 1.5rem;
  white-space: pre;
  font-size: 0.9375rem;
  opacity: 1;
  transition: transform 0.2s linear 0s, background-color 0.2s linear 0s;
  transform: translateY(0px) scale(1.001);
  background-color: black;
}
</style>
