import { render, staticRenderFns } from "./DemoSales.vue?vue&type=template&id=51335559&scoped=true&"
import script from "./DemoSales.vue?vue&type=script&lang=js&"
export * from "./DemoSales.vue?vue&type=script&lang=js&"
import style0 from "./DemoSales.vue?vue&type=style&index=0&id=51335559&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51335559",
  null
  
)

export default component.exports