import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Impressum from './components/Impressum.vue'
import Privacy from './components/Privacy.vue'
import Home from './components/Home.vue'
import Demo from './components/Demo'
import SpeedHiring from './components/SpeedHiring'
import DemoSales from './components/DemoSales.vue'
import About from './components/About.vue'
import Data from './components/Data.vue'
import Developer from './components/Developer.vue'
import Dataimport from './components/Dataimport.vue'
import DemoAutomators from './components/DemoAutomators.vue'


Vue.config.productionTip = false

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: Impressum
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/demo',
    name: 'demo',
    component: Demo
  },
  {
    path: '/speedhiring',
    name: 'speedhiring',
    component: SpeedHiring
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/data',
    name: 'data',
    component: Data
  },
  {
    path: '/developer',
    name: 'developer',
    component: Developer
  },
  {
    path: '/dataimport',
    name: 'dataimport',
    component: Dataimport
  },
  {
    path: '/demo-sales',
    name: 'demo-sales',
    component: DemoSales
  },
  {
    path: '/demo-automators',
    name: 'demo-automators',
    component: DemoAutomators
  }
  
]

const router = new Router({ 
  mode: 'history',
  routes 
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
