<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

// ..
AOS.init();

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import "https://use.fontawesome.com/releases/v5.6.3/css/all.css";
@import "https://fonts.googleapis.com/css?family=Hammersmith+One|Nunito|Orbitron:500|Poiret+One";
@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');

html {
  min-height: 100%;
  
}

body {
  min-height: 100%;
  /*color: #2c3e50;*/
}

.text-dark {
  /*color: #2c3e50!important;*/
  color: var(--bs-body-color)!important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #2c3e50;*/
  min-height: 100%;
}

::v-deep #CybotCookiebotDialogBodyLevelButtonAccept, #CookiebotWidget, #CookiebotWidget-buttons, #CookiebotWidget-btn-change {
  background-color: black;
  border-color: black;
}

::v-deep #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
  color: #141414;
}
::v-deep .btn-primary {
  font-family: Pretendard, sans-serif;
  font-size: 26px;
  line-height: 20px;
  padding: 27px 57px;
  transition: background-color 200ms ease, color 200ms ease;
  border: none;
  border-radius: 38px;
  font-weight: 600;
}

.btn-primary:hover {
  background-color: #d4d1d1;
}

.black {
  background: black;
  color: white;
}

.black .btn-primary {
  background-color: white;
  color: black;
}

.nav-item {
  font-size: 20px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .nav-item {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
  }

  ::v-deep .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: fit-content;
    left: auto;
    right: auto;
    -webkit-animation: show-menu-data-v-8dc7cce2;
    animation: show-menu-data-v-8dc7cce2;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    border-radius: 7px;
    box-shadow: rgb(51 51 51 / 50%) 0px 20px 40px -30px;
    border: solid 0.6px #eeecf2;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f0f0f0;
    color: white;
    font-weight: 900;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-content {
    margin: 100px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu h2 {
    font-size: 17px;
    font-weight: 700;
    padding: 0rem 0rem 1em 2rem;
    border-bottom: solid 1px #cdcdcd;
    margin-bottom: 1rem;
  }

}


.navbar-dark .navbar-brand {
  color: #e4e1e1;
  font-size: 27px;
    font-weight: 500;
}

::v-deep .navbar-dark .navbar-nav .nav-link {
  color: rgb(255 255 255)!important;
  font-weight: 700!important;
}

.navbar {
  background: black;
}

.navbar.navbar-light{
  background: white;
}


.calendly-inline-widget{
  position: relative;
  position: relative;
}

@media (max-width: 500px){
  .calendly-inline-widget{
    min-width: unset;
    height: 500px;
    width: 80%;
    left: 10%;
    margin-top: 10%;
  }
  .screens-wrapper{
    width: 100%;
  }
}

@media (min-width: 500px){
  .calendly-inline-widget{
    position: relative;
    position: relative;
    min-width: 800px;
    height: 738px;
    width: 100%;
  }
}

.screens-wrapper {
  position: relative;
  margin-left: auto;
  border-radius: 22px;
}



</style>
