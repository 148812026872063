<template>
  <div class="wrapper">
    <b-container class="px-lg-5" fluid>
      <div class="container">
        <b-navbar
          toggleable="lg"
          v-bind:type="'light'"
          data-aos="fade-down"
          sticky
        >
          <b-navbar-brand href="/">
            <img src="../assets/logo.svg" />
            Codelos.io
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown text="Lösungen" id="solutions-menu" right>
                <ul>
                  <li>
                    <b-dropdown-item href="/#sales-section"
                      >Für den Vertrieb</b-dropdown-item
                    >
                  </li>
                  <li>
                    <b-dropdown-item href="/#data-section"
                      >Für das Datenmanagement</b-dropdown-item
                    >
                  </li>
                  <li>
                    <b-dropdown-item href="/#automators-section"
                      >Für Citizen Automators</b-dropdown-item
                    >
                  </li>
                  <li>
                    <b-dropdown-item href="/#developers-section"
                      >Für Softwareentwickler</b-dropdown-item
                    >
                  </li>
                </ul>
              </b-nav-item-dropdown>
              <b-nav-item href="/developer" right>Developer</b-nav-item>
              <b-nav-item href="/about" right>Über uns</b-nav-item>
              <b-nav-item href="https://codelos.notion.site/Wir-freuen-uns-auf-deine-Bewerbung-e066250a894a42b9af365c7ffa518dd7" target="_blank" right>Jobs</b-nav-item>
              <b-nav-item href="https://www.app.codelos.io/" right
                >Login</b-nav-item
              >
              <b-nav-item href="mailto:team@codelos.io" right
                >Kontakt aufnehmen</b-nav-item
              >
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <div class="header">
          <div class="row">
            <div
              class="col-md-12 col-xl-6 d-flex py-5 px-4 align-items-center"
              data-aos="fade-up"
            >
              <div>
                <h1>Weniger Arbeit. Mehr Features.</h1>
                <p v-bind:class="{ active: loaded == true }" class="mt-5">
                  Entdecke unsere Microservices und integriere sie via REST-API
                  nahtlos in deine internen Systeme und Werkzeuge.
                </p>
                <b-button variant="primary" href="/demo"
                  >Jetzt loslegen</b-button
                >
              </div>
            </div>
            <div
              class="
                col-md-12 col-xl-6
                my-md-5
                py-md-5
                pb-5
                d-flex
                align-items-center
                aos-init aos-animate
              "
              data-aos="fade-left"
            >
              <div class="screens-wrapper">
                <img
                  alt="Zeitreihen und Lastgang-APIs"
                  class="icon icon-lg"
                  src="../assets/code.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container fluid>
      <div class="section">
        <div class="container text-dark mt-0" data-aos="fade-up">
          <div class="row">
            <div
              class="
                col-md-12 col-xl-12
                d-flex
                py-5
                align-items-center
                text-center
              "
            >
              <h1 class="text-center"></h1>
            </div>
          </div>
          <div class="row apis">
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M448 80V128C448 172.2 347.7 208 224 208C100.3 208 0 172.2 0 128V80C0 35.82 100.3 0 224 0C347.7 0 448 35.82 448 80zM393.2 214.7C413.1 207.3 433.1 197.8 448 186.1V288C448 332.2 347.7 368 224 368C100.3 368 0 332.2 0 288V186.1C14.93 197.8 34.02 207.3 54.85 214.7C99.66 230.7 159.5 240 224 240C288.5 240 348.3 230.7 393.2 214.7V214.7zM54.85 374.7C99.66 390.7 159.5 400 224 400C288.5 400 348.3 390.7 393.2 374.7C413.1 367.3 433.1 357.8 448 346.1V432C448 476.2 347.7 512 224 512C100.3 512 0 476.2 0 432V346.1C14.93 357.8 34.02 367.3 54.85 374.7z"
                  />
                </svg>
              </div>
              <h2>Datenbanken</h2>
              <p>
                Erstelle, speichere und verwalte Datenbanken in allen Größen.
                Halte die persistenten Daten aus jedem deiner Microservices
                privat und steuere diese programmatisch über die API.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"
                  />
                </svg>
              </div>
              <h2>Forms</h2>
              <p>
                Generiere Formulare, die sich sowohl auf großen als auch auf
                kleinen Bildschirmen aufrufen lassen. Lege Validierungsregeln
                für Antworten fest und binde die Formulare einfach in deiner
                Website ein.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
                  />
                </svg>
              </div>
              <h2>E-Mails</h2>
              <p>
                Nutze die robuste und skalierbare E-Mail-API um schnell tausende
                von E-Mails im Handumdrehen zu versenden. Zuverlässig und sicher
                ohne die eigene Infrastruktur zu belasten.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
          </div>
          <div class="row apis">
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M176 322.9l.0002-114.9c0-8.75-7.25-16-16-16s-15.1 7.25-15.1 16L144 322.9c-18.62 6.625-32 24.25-32 45.13c0 26.5 21.5 48 48 48s48-21.5 48-48C208 347.1 194.6 329.5 176 322.9zM272 278.5V112c0-61.87-50.12-112-111.1-112S48 50.13 48 112v166.5c-19.75 24.75-32 55.5-32 89.5c0 79.5 64.5 143.1 144 143.1S304 447.5 304 368C304 334 291.8 303.1 272 278.5zM160 448c-44.13 0-80-35.87-80-79.1c0-25.5 12.25-48.88 32-63.75v-192.3c0-26.5 21.5-48 48-48s48 21.5 48 48v192.3c19.75 14.75 32 38.25 32 63.75C240 412.1 204.1 448 160 448z"
                  />
                </svg>
              </div>
              <h2>Wetterdaten</h2>
              <p>
                Erhalte schnellen und einfachen Zugriff auf genaue Wetter- und
                Klimadaten für jeden Standort in Deutschland. Erhalte bis zu 20
                Jahre historische Daten für deine Analysen und Modelle.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM192 304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304zM336 256C327.2 256 320 263.2 320 272V304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336zM64 432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80C71.16 384 64 391.2 64 400V432zM208 384C199.2 384 192 391.2 192 400V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208zM320 432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336C327.2 384 320 391.2 320 400V432z"
                  />
                </svg>
              </div>
              <h2>Feiertage</h2>
              <p>
                Einfach und schnell alle deutsche Feiertage nach Bundesländern
                per REST-API abfragen.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M64 400C64 408.8 71.16 416 80 416H480C497.7 416 512 430.3 512 448C512 465.7 497.7 480 480 480H80C35.82 480 0 444.2 0 400V64C0 46.33 14.33 32 32 32C49.67 32 64 46.33 64 64V400zM342.6 278.6C330.1 291.1 309.9 291.1 297.4 278.6L240 221.3L150.6 310.6C138.1 323.1 117.9 323.1 105.4 310.6C92.88 298.1 92.88 277.9 105.4 265.4L217.4 153.4C229.9 140.9 250.1 140.9 262.6 153.4L320 210.7L425.4 105.4C437.9 92.88 458.1 92.88 470.6 105.4C483.1 117.9 483.1 138.1 470.6 150.6L342.6 278.6z"
                  />
                </svg>
              </div>
              <h2>Marktpreise</h2>
              <p>
                Erhalte die aktuellen sowie die historischen Energiepreise für
                die verschiedenen Terminprodukte.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
          </div>
          <div class="row apis">
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM288.1 344.1C284.3 349.7 278.2 352 272 352s-12.28-2.344-16.97-7.031L216 305.9V408c0 13.25-10.75 24-24 24s-24-10.75-24-24V305.9l-39.03 39.03c-9.375 9.375-24.56 9.375-33.94 0s-9.375-24.56 0-33.94l80-80c9.375-9.375 24.56-9.375 33.94 0l80 80C298.3 320.4 298.3 335.6 288.1 344.1z"
                  />
                </svg>
              </div>
              <h2>Lastgang-Importer</h2>
              <p>
                Importiere, bereinige und vervollständige hunderte von
                Zeitreihen in Minuten. Von Dateicodierungen bis hin zu hunderte
                verschiedene Datumsformaten, die API erkennt, überprfüft und
                liest alle gängigen Formate und Strukturen.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M511.8 287.6L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L416 100.7V64C416 46.33 430.3 32 448 32H480C497.7 32 512 46.33 512 64V185L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6L511.8 287.6z"
                  />
                </svg>
              </div>
              <h2>SLP Profile</h2>
              <p>
                Erstelle Standardlastprofile für die unterschiedlichen
                Kundengruppen und Abnahmeverhalten. Skaliere die Profile und
                bearbeite sie nach Bedarf.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>

            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M64 400C64 408.8 71.16 416 80 416H480C497.7 416 512 430.3 512 448C512 465.7 497.7 480 480 480H80C35.82 480 0 444.2 0 400V64C0 46.33 14.33 32 32 32C49.67 32 64 46.33 64 64V400zM128 320V236C128 228.3 130.8 220.8 135.9 214.1L215.3 124.2C228.3 109.4 251.4 109.7 263.1 124.8L303.2 171.8C312.2 182.7 328.6 183.4 338.6 173.4L359.6 152.4C372.7 139.3 394.4 140.1 406.5 154.2L472.3 231C477.3 236.8 480 244.2 480 251.8V320C480 337.7 465.7 352 448 352H159.1C142.3 352 127.1 337.7 127.1 320L128 320z"
                  />
                </svg>
              </div>
              <h2>Lastprognose</h2>
              <p>
                Erstelle Lastprognosen im Handumdrehen. Von der einfachen
                Lastgang-Ausrollung über eine temperaturabhängige Prognose bis
                hin zu komplexen Prognosemodellen, die API stellt dir alle
                gängigen Modelle zur Verfügung.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
          </div>
          <div class="row apis">
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M336 0h-288C22.38 0 0 22.38 0 48v416C0 489.6 22.38 512 48 512h288c25.62 0 48-22.38 48-48v-416C384 22.38 361.6 0 336 0zM64 208C64 199.2 71.2 192 80 192h32C120.8 192 128 199.2 128 208v32C128 248.8 120.8 256 112 256h-32C71.2 256 64 248.8 64 240V208zM64 304C64 295.2 71.2 288 80 288h32C120.8 288 128 295.2 128 304v32C128 344.8 120.8 352 112 352h-32C71.2 352 64 344.8 64 336V304zM224 432c0 8.801-7.199 16-16 16h-128C71.2 448 64 440.8 64 432v-32C64 391.2 71.2 384 80 384h128c8.801 0 16 7.199 16 16V432zM224 336c0 8.801-7.199 16-16 16h-32C167.2 352 160 344.8 160 336v-32C160 295.2 167.2 288 176 288h32C216.8 288 224 295.2 224 304V336zM224 240C224 248.8 216.8 256 208 256h-32C167.2 256 160 248.8 160 240v-32C160 199.2 167.2 192 176 192h32C216.8 192 224 199.2 224 208V240zM320 432c0 8.801-7.199 16-16 16h-32c-8.799 0-16-7.199-16-16v-32c0-8.801 7.201-16 16-16h32c8.801 0 16 7.199 16 16V432zM320 336c0 8.801-7.199 16-16 16h-32c-8.799 0-16-7.199-16-16v-32C256 295.2 263.2 288 272 288h32C312.8 288 320 295.2 320 304V336zM320 240C320 248.8 312.8 256 304 256h-32C263.2 256 256 248.8 256 240v-32C256 199.2 263.2 192 272 192h32C312.8 192 320 199.2 320 208V240zM320 144C320 152.8 312.8 160 304 160h-224C71.2 160 64 152.8 64 144v-64C64 71.2 71.2 64 80 64h224C312.8 64 320 71.2 320 80V144z"
                  />
                </svg>
              </div>
              <h2>Zeitreihen-Operationen</h2>
              <p>
                Mit der Zeitreihen-Operationen-API kannst du die am häufigsten
                genutzten arithmetischen Zeitreihenoperationen durchführen.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M575.4 25.72C572.4 10.78 559.2 0 543.1 0H96c-15.25 0-28.39 10.78-31.38 25.72l-63.1 320c-1.891 9.406 .5469 19.16 6.625 26.56S22.41 384 32 384h255.1v64.25H239.8c-26.26 0-47.75 21.49-47.75 47.75c0 8.844 7.168 16.01 16.01 16l223.1-.1667c8.828-.0098 15.99-7.17 15.99-16C447.1 469.5 426.6 448 400.2 448h-48.28v-64h256c9.594 0 18.67-4.312 24.75-11.72s8.516-17.16 6.625-26.56L575.4 25.72zM517.8 64l19.2 96h-97.98L429.2 64H517.8zM380.1 64l9.617 96H250l9.873-96H380.1zM210.8 64L201 160H103.1l19.18-96H210.8zM71.16 320l22.28-112h102.7L184.6 320H71.16zM233.8 320l11.37-112h149.7L406.2 320H233.8zM455.4 320l-11.5-112h102.7l22.28 112H455.4z"
                    />
                  </svg>
                </svg>
              </div>
              <h2>PV Erzeugungs-Profile</h2>
              <p>
                Erzeuge Photovoltaik-Erzeugungsprofile für verschiedene
                Typtagkategorien und Regionen. Skaliere die Profile nach deinem
                eigenen Bedarf.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
            <div class="col-md description-leftight p-5 card my-3 m-md-3">
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM128 224C110.3 224 96 238.3 96 256V352C96 369.7 110.3 384 128 384C145.7 384 160 369.7 160 352V256C160 238.3 145.7 224 128 224zM192 352C192 369.7 206.3 384 224 384C241.7 384 256 369.7 256 352V160C256 142.3 241.7 128 224 128C206.3 128 192 142.3 192 160V352zM320 288C302.3 288 288 302.3 288 320V352C288 369.7 302.3 384 320 384C337.7 384 352 369.7 352 352V320C352 302.3 337.7 288 320 288z"
                  />
                </svg>
              </div>
              <h2>Hedgen</h2>
              <p>
                Zerlege deine Lastgangprognosen in handelbare Standardprodukte.
                Mit dem Slim EDM werden Beschaffungs- und Vertriebsprozesse
                erheblich erleichtert.
              </p>
              <a class="mt-3 mb-3 btn" :class="{'mobile':isMobile}" href="/demo">API nutzen</a>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <!--<b-container class="black px-lg-5" fluid>
      <div class="container">
        <div class="banner">
          <div class="row">
            <div class="col-md-12 col-xl-12 d-flex py-5 px-4 align-items-center"
              data-aos="fade-up">
              <div>
                <h1>Arbeite effizienter, minimiere Fehler und bringe
                deine digitale Transformation in Gang.</h1>
                <b-button variant="primary" href="#/subscribe"
                  >Jetzt loslegen</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>-->
    <b-container fluid class="light-gray-blue mt-5">
      <div class="container">
        <div class="footer">
          © Codelos.io GmbH
          <br /><br />
          Mit ❤️ gemacht in München, Berlin, Porto und Ko Samui.
          <br /><br />
          <ul class="inline-list">
            <li>
              <u><a href="/impressum">Impressum</a></u>
            </li>
            <li>
              <u><a href="/privacy">Datenschutzerklärung</a></u>
            </li>
            <li>
              <a
                href="https://www.facebook.com/Codelosio-GmbH-103666008172517"
                target="blank"
                class="mr3"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/codelos"
                target="blank"
                class="mr3"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
            <!--<li>
              <a
                href="https://www.xing.com/pages/codelos-iogmbh"
                target="blank"
                class="mr3"
                ><i class="fab fa-xing"></i
              ></a>
            </li>-->
            <li>
              <a
                href="https://www.instagram.com/codelos.io/"
                target="blank"
                class="mr3"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Developer",
  data() {
    return {
      loaded: false,
      navbarType: "dark",
    };
  },
  methods: {
    changeNavbarType() {
      this.navbarType == (this.navbarType == "light" ? "dark" : "light");
    },
  },
  mounted() {
    setTimeout(() => (this.loaded = true), 9000);
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  height: 100%;
}

/** Navbar **/
.navbar-brand img {
  height: 40px;
}

.navbar-light {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.nav-link.dropdown-toggle {
  color: #000;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #000;
}

::v-deep ul.dropdown-menu {
  background: #ffff;
  margin-top: 18px;
  list-style-type: none !important;
}

::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.28rem 2rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

::v-deep .dropdown-toggle {
  outline: none;
}

/** Container **/
.container {
  height: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.header {
  text-align: left;
}

.header h1 {
  margin: 0px;
  font-size: 91px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.035em;
  text-transform: none;
}

.header p {
  margin-bottom: 5vh;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.01em;
  font-family: Pretendard, sans-serif;
}

.banner {
  text-align: center;
}

.banner h1 {
  margin: 0px;
  font-size: 60px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.035em;
  text-transform: none;
}

.lead {
  padding-top: 60px;
  padding-bottom: 60px;
}

.black .btn-primary {
  background-color: white;
  color: black;
}

.btn-primary {
  font-family: Pretendard, sans-serif;
  font-size: 26px;
  line-height: 20px;
  padding: 27px 57px;
  transition: background-color 200ms ease, color 200ms ease;
  border: none;
  border-radius: 38px;
  font-weight: 600;
}

@media (max-width: 500px) {
  .black .btn-primary,
  .btn-primary {
    padding-left: 35px;
    font-size: 22px;
    padding-right: 35px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.black .btn-primary:hover {
  background-color: #ffffffd9;
  transition: 0.4s;
}

.btn-primary:hover {
  background-color: #d4d1d1;
}

video {
  width: 100%;
  border-radius: 30px;
}

.demos {
  margin-top: 25px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.preview-element {
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 31%);
  transition-property: border-color;
  transition-duration: 350ms;
}

.video-wrapper {
  padding: 0;
  margin-top: 6%;
  margin-bottom: 6%;
}

.orange {
  background-color: #ff7745;
}

.blue {
  background-color: #3b85f7;
}

.mauve {
  background-color: #944ff6;
}

.green {
  background-color: #6eecd5;
}

.gray {
  background-color: #cdcdcd;
}

.light-gray-blue-gradient {
  background: linear-gradient(-45deg, #fff2f5, #f7f5ff, #edfbff);
  background: #f2f8ff;
}

.light-gray-blue {
  background: #f3f4f6;
}

.black {
  background: black;
  color: white;
}

.black .btn-primary {
  background-color: white;
  color: black;
}

.light-gray {
  background-color: #f8f9fc;
}

.preview-element .title {
  text-align: center;
  padding: 19px;
  font-size: 19px;
  font-weight: 500;
  background: #ffff;
}

/* footer */
.footer {
  padding-top: 110px;
  text-align: left;
  padding-bottom: 50px;
  padding-left: 0;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer ol,
ul {
  padding-left: 0px;
  list-style: none;
}

.inline-list {
  list-style: unset;
}

.inline-list li {
  display: inline-block;
  margin-right: 15px;
}

.inline-list li a:hover {
  text-decoration: underline;
}
/* end footer */

a.dropdown-item {
  background-color: #212529;
}

.dropdown-menu {
  border-radius: 0px;
}

.highlight {
  background-image: linear-gradient(
    to right,
    rgb(255 231 133),
    rgb(255 231 133)
  );
  background-size: 0% 1.12em;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: background-size 2000ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.active .highlight {
  background-size: 100% 1.12em;
}

.drag-n-drop-anim {
  animation: drag;
  animation-duration: 3s;
  position: relative;
  display: block;
}

.change-anim-list {
  overflow: hidden;
  height: 70px;
}

.change-anim-item {
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  position: relative;
  display: block;
}

.fa-mouse-pointer {
  font-size: 38px;
  color: black;
  margin-top: -22px;
  display: block;
  margin-left: 49px;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffff;
  height: 22px;
}

@keyframes change {
  0%,
  12.66%,
  100% {
    top: 0px;
  }
  16.66%,
  29.32% {
    top: -85px;
  }
  33.32%,
  45.98% {
    top: -170px;
  }
  49.98%,
  62.64% {
    top: -255px;
  }
  66.64%,
  79.3% {
    top: -340px;
  }
  83.3%,
  95.96% {
    top: -425px;
  }
}

@keyframes drag {
  0% {
    left: -600px;
    top: 900px;
  }
  30% {
    left: -600px;
    top: 900px;
  }
  60% {
    left: 60px;
    top: 20px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

@keyframes zoom-in {
  0% {
    width: 0%;
    height: 0px;
  }
  75% {
    width: 30%;
    height: 50px;
  }
  100% {
    width: 100%;
    height: 148px;
  }
}

@keyframes show-menu {
  0% {
    opacity: 0;
    margin-left: -20px;
    width: 60%;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@media screen and (max-width: 479px) {
  .header h1 {
    font-size: 50px;
  }
  .header p {
    font-size: 14px;
    padding-bottom: 25px;
  }
  .preview-element {
    margin-top: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .nav-item {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
  }

  ::v-deep .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: fit-content;
    left: auto;
    right: auto;
    -webkit-animation: show-menu-data-v-8dc7cce2;
    animation: show-menu-data-v-8dc7cce2;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    border-radius: 7px;
    box-shadow: rgb(51 51 51 / 50%) 0px 20px 40px -30px;
    border: solid 0.6px #eeecf2;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f0f0f0;
    color: white;
    font-weight: 900;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-content {
    margin: 100px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu h2 {
    font-size: 17px;
    font-weight: 700;
    padding: 0rem 0rem 1em 2rem;
    border-bottom: solid 1px #cdcdcd;
    margin-bottom: 1rem;
  }

  ::v-deep .dropdown-item {
    font-size: 16px;
  }
}

/*@media (min-width: 1200px) {

}*/

::v-deep .dropdown-menu {
  padding: 27px 0 26px;
}

.container.gray {
  background-color: #f7f7f9;
}

.header img {
  width: 100%;
  box-shadow: 0 1px 10px rgb(0 0 0 / 7%), 0 2.4px 5.7px rgb(0 0 0 / 2%),
    0 4px 11.3px rgb(0 0 0 / 2%), 0 10.6px 17.5px rgb(0 0 0 / 2%),
    0 14px 25.7px rgb(0 0 0 / 3%), 0 35.5px 39.6px rgb(0 0 0 / 4%),
    0 40px 48px rgb(0 0 0 / 3%);
  border-radius: 9px;
}

.screens-wrapper {
  width: 100%;
}

.screen-1 {
  margin-top: 36%;
  left: 5%;
  z-index: 1;
  position: relative;
  float: left;
  width: 39%;
}

.screen-2 {
  left: 0px;
  margin-top: 0px;
  position: relative;
  float: right;
  width: 61%;
}

.row.feature img {
  width: 100%;
  border-radius: 22px;
  margin-top: 6%;
  margin-bottom: 6%;
}

.container {
  max-width: 1443px;
}

.container-fluid {
  width: 100%;
}

@media screen and (min-width: 479px) {
  .container-fluid .container {
    padding-right: 60px;
    padding-left: 60px;
  }
}

h3 {
  font-size: calc(0.7rem + 0.6vw);
}

h2 {
  font-family: Avenir Next, Avenir Next W01, Avenir, helvetica, arial;
  font-size: 1.77777778rem;
  line-height: 2.66666667rem;
  margin-bottom: 0.5em;
  font-weight: 700;
}

@media (min-width: 1200px) {
  h2 {
    font-size: 2.3rem;
  }
}

.description-right {
  padding-left: 5%;
}

.description-left {
  padding-right: 5%;
}

.ml-auto {
  margin-left: auto !important;
}

.navbar-dark .navbar-brand {
  color: #e4e1e1;
  font-size: 27px;
  font-weight: 500;
}

::v-deep .navbar-dark .navbar-nav .nav-link {
  color: rgb(255 255 255) !important;
  font-weight: 700 !important;
}

.btn-primary {
  background-color: black;
  color: white;
}

.row.apis img {
  width: 100%;
  border-radius: 22px;
  margin-top: 6%;
  margin-bottom: 6%;
}

.row.apis .col {
  width: 100px;
}

.card {
  border-radius: 22px;
  background: #f3f4f6;
  border: none;
  transition: 0.2s ease;
}
.card:hover {
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 23%);
  transform: translateY(-4px);
}
.card .icon-wrapper {
  width: 80px;
  padding: 15px;
  border-radius: 50%;
  background: black;
  height: 80px;
  margin-bottom: 25px;
  text-align: center;
  overflow: hidden;
  padding-top: 21px;
}

.card .icon-wrapper svg {
  fill: white;
  width: 51px;
}

ul {
  list-style-type: none;
  list-style: none;
}

.card .btn {
  text-decoration: none;
  color: white;
  border: 3px solid black;
  border-radius: 0.375em;
  cursor: pointer;
  display: inline-block;
  font-family: proxima-nova, "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  line-height: 23px;
  padding: 0.5rem 1.5rem;
  white-space: pre;
  font-size: 0.9375rem;
  transition: transform 0.2s linear 0s, background-color 0.2s linear 0s;
  transform: translateY(0px) scale(1.001);
  background-color: black;
  width: fit-content;
  opacity: 0;
}

.card .btn.mobile {
  opacity: 1;;
}

.card:hover .btn {
  opacity: 1;
  transition: transform 0.5s;
  transform: translateY(-5px);
}
</style>
